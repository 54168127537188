






































































import { Notify,Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullSelItem from "../../components/ShareComponent/PullSelItem.vue"
import { AddressType,RoomType,AddAddressType as UpDataType } from "../../Type/index"
import Stroage from "../../util/Storage"
import { GetSchoolRoom,SetAddAddress,ChangeAddress } from "../../Api/Basics/index"
import { UserStore } from "../../store/modules/User"
import { FilterPullItem } from "../../util/filter"
import { Vue,Component,Ref } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface AddAddressType<T=string>{
    userId:T|number;
    token:T;
    rightIcon:T;
    title:T;
    write:boolean;
    data:AddressType;
    name:T;
    phone:T;
    room:T;
    SelIndex:number;
    SelAddress:RoomType[];
    UpData:UpDataType;
    loadingShow:boolean;

    initData():void;
    handleFilterIndex():void;
    handleChangeItem(data:any):void;
    handleUpRoom():void;
    handleUpData():void;
    handleWriteData():void;
    handleError(data:string):void;
}

@Component({ name:"AddAddress",components:{ PullSelItem,HeadTop } })
export default class AddAddress extends ZoomPage implements AddAddressType{
    userId:string|number = ""
    token = ""
    rightIcon = require("../../assets/icon/Basics/Right.png");
    title = "添加地址";
    write = false
    data:AddressType = {}
    name = ""
    phone = ""
    room = ""
    SelIndex = 0
    SelAddress:RoomType[] = [ ]
    UpData:UpDataType = {
        name:"",
        phone:"",
        address:"",
        buildNumberName:""
    }
    loadingShow = true

   @Ref("SelItem")
   SelItem!:any

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.initData()
    }

    initData(){
        let { write,title,data } = this.$route.query
        let reg = /true/g
        if( Boolean(write) && reg.test( write as string) ){
            this.write = true
            if( title ){
               this.title = title as string
            }
            if( data ){
                this.data = JSON.parse( data as string )
                this.name = this.data.name as string
                this.phone = this.data.phone as string
                this.room = this.data.address as string
            }
        }

        if( UserStore.GetRoomList.length ){
            this.loadingShow = false
            this.SelAddress = FilterPullItem( UserStore.GetRoomList,"dicCode")
            if( this.write ){
                this.handleFilterIndex()
            }else{
               this.UpData.buildNumberName = this.SelAddress[0].dicCode as string
            }
        }else{
            this.handleUpRoom()
        }
    }

   handleFilterIndex(){
       if( this.data.buildNumberName ){
           let Index = this.SelAddress.findIndex((a,b)=>a.dicCode == this.data.buildNumberName)
           if( Index < 0 ){
               Index = 0
           }
           this.SelIndex = Index
           this.UpData.buildNumberName = this.SelAddress[Index].dicCode as string
       }
   }

   handleChangeItem(data:any){
       this.UpData.buildNumberName = data.data.title
       if( this.write ){
           let  { room } = this;
           let KeyList = room.split("#")
           if( KeyList.length > 1 ){
               this.room = this.UpData.buildNumberName + "#" + KeyList[1]
           }else{
               this.room = this.UpData.buildNumberName + "#" + room
           }
       }
   }

   handleUpRoom(){
       GetSchoolRoom({
           userId:this.userId,
           token:this.token
       }).then( (res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               this.SelAddress = FilterPullItem( res.data.buildNumbers,"dicCode")
               UserStore.SetRoomList( res.data.buildNumbers )
               if( this.write ){
                   this.handleFilterIndex()
               }else{
                   this.UpData.buildNumberName = this.SelAddress[0].dicCode as string
               }
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleUpData(){
       let { name,phone,room } = this
       let reg = / /g
       name = name.replace(reg,"")
       phone = String( phone ).replace(reg,"")
       room = room.replace(reg,"")
       if( name.length ){
           this.UpData.name = name
       }else{
           this.handleError("请输入名字")
           return
       }
       if( (String( phone ).length === 11) ){
           this.UpData.phone = phone
       }else{
           this.handleError("请输入手机号")
           return
       }
       if( room.length ){
           if( this.UpData.buildNumberName.length ){
                this.UpData.address = this.UpData.buildNumberName + "#" + room
           }else{
               this.handleError("请选择宿舍楼号")
               return
           }
       }else{
           this.handleError("请输入房间号")
           return
       }
       this.loadingShow = true
       SetAddAddress({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               Toast({
                   message:"添加成功",
                   icon:"none",
                   duration:1500,
                   className:"ToastClassName"
               })
               let time = setTimeout(()=>{
                   this.$router.go(-1)
                   clearTimeout(time)
               },1000)
           }else{
               this.handleError(res.message.msg)
           }
       } )
   }

   handleWriteData(){
       let { name,phone,room } = this;
       let reg = / /g
       name = name.replace(reg,"")
       phone = String( phone ).replace(reg,"")
       room = room.replace(reg,"")
       let Num = 0;
       if( this.data.name === name ){ Num ++; }
       if( this.data.phone === phone ){ Num ++; }
       if( this.UpData.buildNumberName === this.data.buildNumberName ){ Num ++; }
       if( this.data.address === room ){ Num ++; }
       if( Num == 4 ){ this.handleError("修改地址一致"); return; }
       this.UpData.name = name
       this.UpData.phone = phone
       this.UpData.address = room
       this.UpData.id = this.data.id
       this.loadingShow = true
       ChangeAddress({
           userId:this.userId,
           token:this.token
       },this.UpData).then((res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               Toast({
                   message:"修改成功",
                   icon:"none",
                   duration:1500,
                   className:"ToastClassName"
               })
               let time = setTimeout(()=>{
                   this.$router.go(-1)
                   clearTimeout(time)
               },1000)
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
